@import "../../utils/Constants.scss";

.About {
    .about-text {
        min-width: 300px;
        max-width: 50%;
    }
    .illustration-about {
        min-width: 200px;
        max-width: 50%;
        img {
            display: block;
            max-width: 400px;
            width: auto;
            height: auto;
            box-shadow: 0px 0px 15px 2px rgba(0, 66, 106, 0.05) !important;
            border-radius: 10px !important;
            margin: 1em;
        }
    }

    .sub-title {
        p {
            font-size: 2.7vh !important;
            line-height: 3.5vh !important;
            padding-bottom: 28px !important;
        }
    }
    @media screen and (max-width: 900px) {
        .about-text {
            min-width: 100%;
        }
        .illustration-about {
            width: 100%;
            min-width: 100%;
            img {
                display: block;
                max-width: 80%;
                margin: auto;
                margin-top: 2em;
            }
        }
    }

    .portfolio {
        position: relative;
        background-color: $background-color;
        box-shadow: 0px 0px 25px rgba(0, 66, 106, 0.05);
        padding-top: 100px;
        padding-bottom: 50px;
    }
}
