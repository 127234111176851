@import "../../utils/Constants.scss";

.ToogleLanguage {
  .flagcontainer {
    padding: 1em;
    border-radius: 5px;
    img {
      height: 1.5em;
    }
  }
  .flagcontainer:hover {
    background-color: $secondary-color;
    cursor: pointer;
  }
  .selected {
    background-color: $secondary-color;
  }
}
