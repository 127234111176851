@import "../../utils/Constants.scss";

.PortfolioComp {
  .filtering {
    span {
      padding: 8px 20px;
      margin: 0 5px;
      color: #777;
      letter-spacing: 1px;
      font-weight: 600;
      font-size: 13px;
      cursor: pointer;
      border: 1px solid #eee;
      border-radius: 10px;
    }

    .active {
      color: #fff;
      background: $primary-color;
      border-color: $primary-color;

      &:after {
        bottom: 0;
        top: calc(100% - 1px);
        opacity: 1;
      }

      &:before {
        top: 0;
        opacity: 1;
      }
    }
  }

  .items {
    padding: 0 15px;
    margin-bottom: 30px;
  }

  .item-img {
    position: relative;
    overflow: hidden;

    h5 {
      font-size: 2em;
      position: relative;
      transform: translateY(15px);
      transition: all 0.6s;
      opacity: 0;
      z-index: 5;
    }

    h6 {
      color: $primary-color;
      font-size: 1.5em;
      position: relative;
      margin-bottom: 10px;
      transform: translateY(-15px);
      transition: all 0.6s;
      opacity: 0;
      z-index: 5;
    }

    p {
      color: $primary-color;
      font-size: 1em;
      position: relative;
      margin-bottom: 10px;
      transform: translateY(15px);
      transition: all 0.6s;
      opacity: 0;
      z-index: 5;
    }

    .link {
      position: absolute;
      bottom: 10px;
      right: 10px;
      color: $primary-color;
      transition: all 0.5s;
      z-index: 5;
      opacity: 0;
    }
  }

  .item-img-overlay {
    position: absolute;
    top: 5px;
    left: 5px;
    right: 5px;
    bottom: 5px;
    background: transparent;
    padding: 1em;
    z-index: 2;
    text-align: center;

    &:before,
    &:after {
      content: "";
      position: absolute;
      width: 0;
      height: 50%;
      background: #fff;
      transition: all 0.4s;
    }

    &:before {
      left: 0;
      top: 0;
    }

    &:after {
      right: 0;
      bottom: 0;
    }

    &:hover {
      &:before,
      &:after {
        width: 100%;
      }
    }

    &:hover h6,
    &:hover h5,
    &:hover p,
    &:hover .link {
      transform: translateY(0);
      opacity: 1;
    }
  }

  .filters-group {
    text-align: center;
    margin-bottom: 1em;
    button {
      margin: 3px;
    }
  }
}
