@import "../../utils/Constants.scss";

.Footer {
  a {
    color: #fefefe;
  }

  margin: 0;
  margin-top: 10em;
  width: 100%;
  color: #fefefe;
  .footer-top {
    background-color: $primary-color;
    .bg-container {
      position: absolute;
      right: 0;
      width: 100%;
      height: 250px;
      overflow: hidden;
      .img-bg {
        position: absolute;
        top: 0;
        right: 0;
        opacity: 0.8;
        height: 250px;
      }
    }

    .container {
      padding: 2em;
      //text-align: center !important;
      h3 {
        font-size: 1.5em !important;
        padding-bottom: 0.5em;
        padding-top: 1.5em;
      }
      p {
        font-size: 1.1em !important;
        line-height: 2em !important;
      }
      .about {
        line-height: 1.3em !important;
        text-align: justify;
      }
    }
  }
  .copyright {
    width: 100%;
    background-color: $primary-color;
    padding: 1em;
    padding-left: 2em;
    padding-right: 2em;
    p {
      font-size: 1em !important;
    }
  }
  h3,
  p {
    color: $background-color !important;
  }
}
@media screen and (max-width: 900px) {
}
