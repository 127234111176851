@import "../../utils/Constants.scss";

.Contact {
    .sub-title {
        p {
            font-size: 3vh !important;
            line-height: 4vh !important;
            padding-bottom: 28px !important;
            text-align: justify;
            font-family: "Josefin Sans", sans-serif !important;
        }
    }
    .header {
        .flip-card {
            width: 500px;
            height: 325px;
            perspective: 1000px;
        }

        .content-flip-card {
            position: absolute;
            width: 100%;
            height: 100%;
            box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);

            transition: transform 1s;
            transform-style: preserve-3d;

            .front-flip-card,
            .back-flip-card {
                position: absolute;
                height: 100%;
                width: 100%;
                background: $background-color;
                color: $primary-color;
                border-radius: 5px;
                backface-visibility: hidden;
            }

            .back-flip-card {
                background: #03446a;
                color: white;
                transform: rotateY(180deg);
                img {
                    display: inline;
                    max-height: 200px;
                    max-width: 200px;
                }
                p {
                    color: $background-color !important;
                    margin-top: 10px !important;
                }
            }

            .front-flip-card {
                background: #fefefe;
                .header-card {
                    height: 50px;
                    overflow: hidden;
                    background-color: $primary-color;
                    color: $background-color !important;
                    padding-right: 1em;
                    padding-left: 1em;
                    img {
                        height: 40px;
                        width: 40px;
                        margin-top: 5px;
                    }
                    span {
                        line-height: 50px;
                        font-size: 1.5em;
                    }
                }
                h3 {
                    text-align: center;
                    padding: 0;
                    padding-top: 15px;
                    margin: 0;
                }
                h4 {
                    text-align: center;
                    margin-bottom: 10px;
                }
                .qr-code-container {
                    img {
                        width: 150px;
                        margin: auto;
                    }
                }
            }
        }

        .flip-card:hover .content-flip-card {
            transform: rotateY(180deg);
            transition: transform 1s;
        }
    }

    @media screen and (max-width: 900px) {
        .flip-card {
            height: 500px !important;
            width: 325px !important;
        }
    }
}
