@import "../../utils/Constants.scss";

html {
  background-color: transparent;
}

body {
  background-color: transparent;
  font-family: "Roboto", sans-serif;
  a:hover {
    color: #fefefe;
  }
  body a {
    text-decoration: none;
    display: inline-block;
  }
  a:hover,
  a:focus,
  a:visited {
    text-decoration: none;
    outline: none;
  }
  img {
    max-width: 100%;
    display: block;
  }
}

#main-background {
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -100;
  background-color: $background-color;
  //background: url(./../../assets/img/background.svg) no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

#particles-js {
  display: block;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -100;
  //background-color: rgba(250,250,250, 0.9);
  //background: url(./../../assets/img/Photo-by-Soragrit-Wongsa-on-Unsplash-sized.jpg) no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  .particles {
    height: 100vh;
    width: 100vw;
  }
}

#main-layout {
  overflow: hidden;
  .mobile-app-header {
    color: #fefefe !important;
  }
  .page-title {
    background-color: $primary-color;
    color: $background-color;
    text-align: end;
    margin-left: 0;
    margin-top: 0;
    padding-top: 1em;
    padding-bottom: 1em;
    margin-bottom: 7em;
    border-bottom-right-radius: 4px;
  }

  .logo {
    color: #fefefe;
    font-size: 36px;
    margin: 0;
    padding: 10px;
    padding-top: 15px;
    padding-right: 1em;
    line-height: 1em;
    //font-family: 'Josefin Sans', sans-serif;
    //font-family: "Poppins", sans-serif;
    font-weight: 600;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    text-align: right;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
      0px 2px 1px -1px rgba(0, 0, 0, 0.12);
    margin-bottom: 1em;
  }

  .mainContent {
    margin: 0;
    padding: 0;
    //margin-top: 3em;
    margin-bottom: 3em;
    z-index: 10;
    //font-family: "Poppins", sans-serif;
    font-family: "Roboto", sans-serif;
    min-height: 100vh;

    .shape-two {
      position: absolute;
      top: 0;
      right: 0;
      opacity: 0.8;
    }
    .shape-three {
      position: absolute;
      left: -70px;
      top: 30%;

      opacity: 0.2;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  .logo {
    font-family: "Josefin Sans", sans-serif;
    color: $primary-color;
  }
  h1 {
    font-size: 5em;
  }
  h2 {
    font-size: 4em;
  }
  h3 {
    font-size: 2em;
  }

  p {
    font-family: "Roboto", sans-serif;
    font-size: 1.2em;
    color: $grey-text;
    line-height: 1.5em;
    margin: 0;
  }

  .section-head {
    text-align: center;
    margin-bottom: 60px;
    .title {
      padding-bottom: 10px;
      position: relative;

      &:after {
        content: "";
        width: 60px;
        height: 1px;
        background: $primary-color;
        position: absolute;
        bottom: 0;
        left: calc(50% - 30px);
      }

      &:before {
        content: "";
        width: 100%;
        height: 1px;
        background: rgba(1, 1, 1, 0.05);
        position: absolute;
        bottom: 0;
        left: 0;
      }
    }
  }

  .full-width {
    width: 100% !important;
  }

  /* Button */
  .solid-button {
    margin: 0.5em;
    font-size: 1em;
    min-width: 200px;
    padding: 1em 2em 1em 2em;
    color: $background-color;
    text-align: center;
    border-radius: 5px;
    box-shadow: 0px 0px 15px rgba(0, 66, 106, 0.05);
    background-color: $primary-color;
    -webkit-transition: background-color 0.2s ease-out, color 0.2s ease-out, border 0.2s ease-out;
    -moz-transition: background-color 0.2s ease-out, color 0.2s ease-out, border 0.2s ease-out;
    -o-transition: background-color 0.2s ease-out, color 0.2s ease-out, border 0.2s ease-out;
    transition: background-color 0.2s ease-out, color 0.2s ease-out, border 0.2s ease-out;
    border: 1px solid $primary-color;
  }
  .solid-button:hover {
    color: $primary-color;
    background-color: $background-color;
  }
  .solid-button .icon-right {
    vertical-align: -1px;
    margin-left: 5px;
  }
  .solid-button .icon-left {
    vertical-align: -1px;
    margin-right: 5px;
  }

  .empty-button {
    margin: 0.5em;
    font-size: 1em;
    min-width: 200px;
    padding: 1em 2em 1em 2em;
    color: $primary-color;
    text-align: center;
    border-radius: 5px;
    box-shadow: 0px 0px 15px rgba(0, 66, 106, 0.05);
    background-color: transparent;
    -webkit-transition: background-color 0.2s ease-out, color 0.2s ease-out, border 0.2s ease-out;
    -moz-transition: background-color 0.2s ease-out, color 0.2s ease-out, border 0.2s ease-out;
    -o-transition: background-color 0.2s ease-out, color 0.2s ease-out, border 0.2s ease-out;
    transition: background-color 0.2s ease-out, color 0.2s ease-out, border 0.2s ease-out;
    border: 1px solid $primary-color;
  }
  .empty-button:hover {
    color: $background-color;
    background-color: $primary-color;
  }
  .empty-button .icon-right {
    vertical-align: -1px;
    margin-left: 5px;
  }
  .empty-button .icon-left {
    vertical-align: -1px;
    margin-right: 5px;
  }

  /* Header part */
  .header {
    //height: 1px;
    min-height: 100vh;
    //position: relative;
    .main-title {
      font-family: "CircularStdmed", "Josefin Sans", sans-serif !important;
      font-size: 8vh !important;
      line-height: 9vh !important;
      padding: 25px 0 35px !important;
    }
    .sub-title {
      font-size: 3vh !important;
      line-height: 4vh !important;
      color: $grey-text !important;
      padding-bottom: 28px !important;
    }
    .illustration {
      margin-left: 2em;
      min-width: 35%;
    }

    @media screen and (max-width: 900px) {
      .main-title {
        text-align: center;
      }
      .sub-title {
        text-align: center;
      }
      .button-group {
        text-align: center;
      }
      .illustration {
        display: none;
      }
      .upper-flex {
        flex-grow: 0.3;
        //display: none;
      }
    }
  }
}

#_drawer-container {
  height: 100%;
  //background: url(./../../assets/img/menu.png) no-repeat top left;
  background-color: rgba(0, 66, 106, 1);
  /*
  @supports ((-webkit-backdrop-filter: blur(10px)) or (backdrop-filter: blur(10px))) {
    background-color: rgba(0, 66, 106, 0.6);
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
  }
  */

  .logo-drawer {
    width: 100%;
    height: 200px;
    margin-bottom: 20px;
    margin-top: 10px;

    background: url(./../../assets/img/logoPlusText.svg) no-repeat center center;
    -webkit-background-size: 100% 200px;
    -moz-background-size: 100% 200px;
    -o-background-size: 100% 200px;
    background-size: 100% 200px;
  }

  .ToogleLanguage {
    width: 100%;
    position: absolute;
    bottom: 0;
  }

  .MuiExpansionPanelSummary-expandIcon {
    color: white;
  }
  .MuiExpansionPanelSummary-content {
    margin: 0;
  }
}
