$primary-color: rgba(0, 66, 106, 1);//#00426A; // Pantone 2188C
$secondary-color: rgb(0, 103, 160);//#006BA6; // Pantone 641C
$background-color: rgb(250,250,250); //#FAFAFA;
$extra-dark-color: rgb(0, 44, 71); //#FAFAFA;
$validate-color: #3dcf8b;
$error-color: #f8465c;
$grey-text: #797d81;

:export {
  primarycolor: $primary-color;
  secondarycolor: $secondary-color;
  backgroundcolor: $background-color;
}
