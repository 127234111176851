@import "../../utils/Constants.scss";

.ValidCookies {
    .button-container {
        margin: auto;
    }
    .solid-button {
        margin: 0.5em;
        font-size: 1em;
        min-width: 200px;
        padding: 1em 2em 1em 2em;
        text-align: center;
        border-radius: 5px;
        box-shadow: 0px 0px 15px rgba(0, 66, 106, 0.05);
    }
}
