@import "../../utils/Constants.scss";
.Home {
    .services {
        position: relative;
        background-color: $background-color;
        box-shadow: 0px 0px 25px rgba(0, 66, 106, 0.05);
        padding-top: 100px;
        padding-bottom: 50px;

        .item {
            border-radius: 10px;
            text-align: center;
            padding: 40px 15px;
            -webkit-transition: background-color 0.5s ease-out;
            -moz-transition: background-color 0.5s ease-out;
            -o-transition: background-color 0.5s ease-out;
            transition: background-color 0.5s ease-out;

            .icon {
                font-size: 40px;
                color: $primary-color;
                margin-bottom: 30px;
            }

            h3 {
                margin-bottom: 15px;
            }

            p {
                text-align: justify;
            }
        }
        .item:hover {
            background-color: rgba(0, 66, 106, 0.05);
            //cursor: pointer;
        }
    }

    .portfolio {
        overflow: hidden;
        position: relative;
        padding-top: 30px;
        padding-bottom: 50px;
        margin-top: 70px;
    }

}
