@import "../../utils/Constants.scss";

.Services {
    .header {
    }

    .text {
        margin-bottom: 3em;
        p {
            text-align: justify;
            padding-bottom: 0.5em;
            //text-indent: 1em;
        }
    }

    .price-web {
        position: relative;
        background-color: $background-color;
        box-shadow: 0px 0px 25px rgba(0, 66, 106, 0.05);
        padding-top: 100px;
        padding-bottom: 50px;
    }

    .price-app {
        position: relative;
        padding-top: 100px;
        padding-bottom: 50px;
    }

    .price-custom {
        position: relative;
        background-color: $background-color;
        box-shadow: 0px 0px 25px rgba(0, 66, 106, 0.05);
        padding-top: 100px;
        padding-bottom: 50px;
    }

    .price-other {
        position: relative;
        padding-top: 100px;
        padding-bottom: 50px;
    }

    .prices-table {
        padding-top: 50px;
        margin-top: 1em;
        margin-bottom: 1em;
    }

    /* Price layout */
    .price-container {
        background-color: $background-color;
        padding: 0 3em 1em 3em;
        box-shadow: 0px 0px 25px 20px rgba(0, 66, 106, 0.05);
        border-radius: 7px;
        margin-bottom: 60px;
        min-height: 650px;
        display: flex;
        flex-direction: column;
        .price-header {
            position: relative;
            top: -30px;
            .price-title {
                background-color: $primary-color;
                padding: 1em;
                box-shadow: 0px 0px 25px 20px rgba(0, 66, 106, 0.05);
                border-radius: 7px;
                h2 {
                    color: $background-color !important;
                    padding: 0 !important;
                    margin: 0 !important;
                    font-size: 2.3em !important;
                }
            }
            .price-price {
                padding: 1.5em;
                padding-bottom: 0;
            }
        }
        .price-body {
            text-align: left;
            position: relative;
            top: -20px;
            p {
                text-align: justify;
                margin-bottom: 1em !important;
            }
            .green {
                color: $validate-color;
            }
            .red {
                color: $error-color;
            }
        }
        .price-button {
            margin-bottom: 1em;
        }
    }
}
